export interface ConfigField {
  extended: boolean;
  key: string;
  label: string;
  required: boolean;
  values?: string[];
  pattern?: string;
  validation?: string;
  options?: {
    displayYears?: 'default' | 'current' | 'next';
  };
  contact1?: { enabled: boolean; required: boolean; };
  contact2?: { enabled: boolean; required: boolean; };
  $choices?: {
    id: string;
    name: string;
  }[];
  $mask?: (string | RegExp)[];
}

export interface School {
  ID: number;
  SchoolName: string;
  SisId: string;
  CustomerId: number;
}

export interface District {
  id: number;
  card: {
    left?: {
      text: string;
    };
    right?: {
      text: string;
    }
  };
  districtName: string;
  links: {
    label: string;
    value: string;
  }[];
  logo: {
    path: string;
  };
  shortname: string;
  fields: ConfigField[];
  schools: School[];
  schoolOptions: {
    allowPreference: boolean;
    allowPreferenceRequired: boolean;
    approvalMaxSchools: number;
    approvalMinSchools: number;
    schoolApproval: boolean;
    schoolPreferenceText: string;
    requireSwiftCode: boolean;
    customInitSuccess?: string;
  };
  nationalization: 'CA' | 'US';
}

/**
 *  Small helper to convert  District.nationalization's country format to Dependent's country format.
 */
export function convertDistrictCountryCodeToDependentCountry( twoCharCountryCode: string ){
  const data: any = { CA: 'Canada', US: 'USA'};
  return data[twoCharCountryCode] || null;
}
