import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { withLayout } from '~/parentPortal/hoc/withLayout';
import { useLoginOnly } from '~/shared/hooks/useLoginOnly';
import { ServiceUrls } from '~/shared/ServiceUrls';
import { ValidateUrls } from '~/shared/ValidateUrls';
import { Box, ErrorPane, Flex, FlexSpacer, PageCenter, Spinner, Theme, NotFound } from '~/ui';
import { useAuth } from '../hooks/useAuth';
import { LoginForm } from './LoginForm';
import { SchoolSelection } from './SchoolSelection';
import * as ReactDOM from 'react-dom';

const Img = styled.img`
  height: 130px;
  margin-bottom: ${Theme.LargeGap};
`;

const Divider = styled.div`
  position: relative;
  width: 1px;
  border: solid 1px ${Theme.LightGrey};
  margin: ${Theme.Gap} ${Theme.LargeGap};
`;

const Pill = styled.div`
  position: absolute;
  background: ${Theme.Grey};
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  left: -20px;
  top: calc(50% - 20px);
`;

const Content = styled(Flex)`
  > form {
    flex: 1;
  }
`;

export const LoginPage = withLayout(null, () => {
  const auth = useAuth();
  const loginOnly = useLoginOnly();

  // Below condition is used to check the Clickjacking
  if (window.self !== window.top) {
    ReactDOM.render(<ErrorPane>Refused to connect - an unexpected error has occurred.</ErrorPane>, document.getElementById('root'));
    return <NotFound />;
  }

  if (auth.authenticated && auth.userInfo && auth.userInfo.email) {
    const params = new URLSearchParams(location.search);
    const reg = new RegExp('<(.|\n)*?>');
    let isUrlValid = false;
    if (params.has('returnTo')) {
      if (location.search.includes(ValidateUrls.enr) || location.search.includes(ValidateUrls.cmp) || location.search.includes(ValidateUrls.doc) || location.search.includes(ValidateUrls.am)) {
        isUrlValid = true;

        // Below block is to check if there is another https inserted in the returnTo parameter to bypass security validation on URLs
        let returnToParam = decodeURIComponent(params.get('returnTo'));
        let trimFirstHttpsInUrl = returnToParam.substring(5, returnToParam.length);
        let checkSecondHttpInUrl = trimFirstHttpsInUrl.indexOf('http');
        let checkSecondHttpsInUrl = trimFirstHttpsInUrl.indexOf('https');

        if (checkSecondHttpInUrl > 0 || checkSecondHttpsInUrl > 0) {
          isUrlValid = false;
        }
      }
      if (location.search.includes('javascript') || reg.test(location.search) || !isUrlValid) {
        window.history.pushState('', '', encodeURIComponent(params.get('returnTo')));
        return <NotFound />;
      }
      else {
        location.href = params.get('returnTo');
      }
      return <></>;
    }
    else if (loginOnly) {
      if (auth.cmpAccess) {
        location.href = ServiceUrls.cmp;
      }
      else if (auth.enrAccess) {
        location.href = `${ServiceUrls.enr}/enroll-admin`;
      }
      else {
        return <PageCenter>
          <div>
            <h2>No Access</h2>
            <p>
              We're sorry, you do not currently have access to our services.
            </p>
          </div>
        </PageCenter>;
      }
      return <PageCenter>
        <div>
          <Spinner size="large" />
          <h2>Please wait...</h2>
        </div>
      </PageCenter>;
    }
    else {
      return <Redirect to="/family" />;
    }
  }

  return <PageCenter>
    <Box width="large">
      <Flex column>
        {auth.notActivated && <ErrorPane>
          Your account has not yet been activated. Please check your email for a message from us
          and click the link inside to complete your registration before attempting to sign-in.
        </ErrorPane>}
        <Content>
          <LoginForm />
          <Divider>
            <Pill>or</Pill>
          </Divider>
          <SchoolSelection />
        </Content>
      </Flex>
    </Box>
  </PageCenter>;
});
